.survey-form-container {
    width: 100%;
  }
  
  .content-wrapper {
    display: flex;
  }
  
  .survey-list-container {
    padding: 1.5rem; /* p-6 */
  }
  
  .survey-list-container h2 {
    font-size: 1.5rem; /* text-2xl */
    font-weight: 700; /* font-bold */
    margin-bottom: 1.5rem; /* mb-6 */
  }
  
  .create-survey-card {
    display: flex;
    align-items: center;
    margin-bottom: 2rem; /* mb-8 */
  }
  
  .create-survey-card span {
    margin-left: 0.5rem; /* ml-2 */
  }
  
  .month-wise-surveys {
    display: grid;
    gap: 1.5rem; /* gap-6 */
    grid-template-columns: 1fr; /* default for mobile */
  }
  
  .month-card {
    background-color: white;
    border-radius: 0.5rem; /* rounded-lg */
    padding: 1.5rem; /* p-6 */
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 
                0 2px 4px -1px rgba(0, 0, 0, 0.06); /* shadow-md */
    transition: box-shadow 0.3s ease; /* transition-shadow */
  }
  
  .month-card:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
                0 4px 6px -2px rgba(0, 0, 0, 0.05); /* hover:shadow-lg */
  }
  
  .month-card h3 {
    font-size: 1.125rem; /* text-lg */
    font-weight: 600; /* font-semibold */
    margin-bottom: 1rem; /* mb-4 */
    color: #374151; /* text-gray-700 */
  }
  
  .month-card .flex-col {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; /* space-y-2 */
  }
  
  .month-card .text-sm {
    font-size: 0.875rem;
    color: #6B7280; /* text-gray-500 */
  }
  
  /* Media Queries for Responsive Grid */
  @media (min-width: 768px) {
    .month-wise-surveys {
      grid-template-columns: repeat(2, 1fr); /* md:grid-cols-2 */
    }
  }
  
  @media (min-width: 1024px) {
    .month-wise-surveys {
      grid-template-columns: repeat(3, 1fr); /* lg:grid-cols-3 */
    }
  }