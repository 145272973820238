.form-container {
  display: flex;
  gap: 20px;
  padding: 20px;
  font-family: Nunito;
  width: 100%;
}

.outerForm {
  display: flex;
  width: 80vw;
  background: rgba(102, 163, 166, 0.35);
  border-radius: 10px;
  width: 70%;
}

.main-content {
  flex: 1 1;
  background: #fff;
  border: 2px solid white;
  border-radius: 20px;
  /* padding: 20px; */
  margin: 20px;
  background: #d9e8e9;
}

.sidebar {
  width: 30%;
  background: #fff;
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px 0 0 20px;
}

.title {
  font-size: 20px;
  margin: 0;
}

.section-title {
  background: #8ab3b9;
  color: white;
  padding: 10px 21px;
  margin-bottom: 20px;
  border-radius: 0 20px 0px 0;
  font-weight: 700;
  text-align: center;
  width: fit-content;
}
.section-title + form {
  padding: 20px 0px 0px 20px;
  height: 54vh;
  overflow-y: scroll;
}

.form-group {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 8px;
}

.input {
  width: 94%;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  appearance: none;
  border: none;
  outline: none;
  border-bottom: 0.5px solid rgba(117, 117, 117, 1);
  background: inherit;
  color: black;
  font-family: Nunito;
  margin-left: 2%;
}

.sideSectionTitle {
  width: 100%;
  text-align: center;
  background: #3384b1;
  margin: 0;
  padding: 0.83em;
  color: white;
}

.navigation-button {
  background: #fff;
  border: 1px solid #8ab3b9;
  color: #8ab3b9;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  margin: 34px 37px 0px 0px;
  width: 8%;
  font-size: 15px;
}

.nav-btns {
  display: flex;
  justify-content: end;
}

.section-item {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 8px;
  background: #f8f8f8;
  border-radius: 4px;
}

.section-item:hover {
  background: #f0f0f0;
}

.section-icon {
  margin-right: 10px;
  color: #8ab3b9;
}

.section-item.activeSection {
  font-weight: bold;
}
